<template>
  <div>
    <div class="hero-section">
      <div class="hero-single">
        <div class="hero-shape-area">
          <img class="hero-shape-1" :src="shape1Img" alt="">
          <img class="hero-shape-4" :src="shape2Img" alt="">
        </div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-8 col-lg-6">
              <div class="hero-content">
                <h6 class="hero-sub-title wow animate__ animate__fadeInUp animated" data-wow-duration="1s"
                    data-wow-delay=".25s"
                    style="visibility: visible; animation-duration: 1s; animation-delay: 0.25s; animation-name: fadeInUp;">
                  Start To New Journey</h6>
                <h1 class="hero-title wow animate__ animate__fadeInUp animated" data-wow-duration="1s"
                    data-wow-delay=".50s"
                    style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeInUp;">
                  Best learning <span>platform that take</span> you next level
                </h1>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="hero-img" :style="{'backgroundImage': 'url('+heroImg+')'}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      shape1Img: require('../../assets/images/shape-1.png'),
      shape2Img: require('../../assets/images/shape-4.png'),
      heroImg: require('../../assets/images/hero.jpg'),
    }
  }
}
</script>

<style scoped>

.hero-section {
  position: relative;
  overflow: hidden;
}

.hero-single {
  background: #EDEEF3;
}

.hero-single .hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
}

.hero-single .hero-content .hero-title {
  color: #202029;
  font-size: 50px;
  font-weight: 800;
  margin: 20px 0;
  text-transform: capitalize;
}

.hero-single .hero-content .hero-title span {
  color: #00AF92;
}

.hero-single .hero-content .hero-sub-title {
  color: #00AF92;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
}

.hero-single .hero-content p {
  color: #202029;;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 20px;
}

.hero-single .hero-content .hero-btn {
  gap: 2rem;
  display: flex;
  margin-top: 1rem;
  justify-content: flex-start;
}

.hero-search {
  margin-top: 20px;
}

.hero-search .form-group {
  position: relative;
}


.hero-search button {
  position: absolute;
  background: #00AF92;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  right: 5px;
  top: 5px;
  transition: all .5s ease-in-out;
}

.hero-search button:hover {
  background: #202029;
  color: #fff;
}

.hero-search i {
  position: absolute;
  left: 20px;
  top: 22px;
  font-size: 18px;
  color: #757F95;
}

.hero-shape-area img {
  position: absolute;
}

.hero-shape-1 {
  bottom: 29%;
  left: 0;
}

.hero-shape-2 {
  top: 40%;
  left: 50%;
}

.hero-shape-3 {
  top: -10px;
  left: 30%;
}

.hero-shape-4 {
  bottom: 10%;
  left: 25%;
}

.hero-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 300px;
  position: relative;
  margin-right: -200px;
  -webkit-clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0 100%);
}

@media all and (max-width: 1199px) {

  .hero-single .hero-content .hero-title {
    font-size: 37px;
  }

  .hero-social-wrapper {
    display: none;
  }
}

@media all and (max-width: 991px) {

  .hero-single .hero-content .hero-title {
    font-size: 35px;
  }

  .hero-single {
    padding-top: 150px;
    padding-bottom: 110px;
  }

  .hero-img {
    display: none;
  }

  .hero-shape-2 {
    top: 45%;
    left: 70%;
  }

  .hero-shape-3 {
    top: 5%;
    left: 50%;
  }

}

@media all and (max-width: 767px) {

  .hero-single .hero-content .hero-sub-title {
    font-size: 18px;
  }

  .hero-single .hero-content .hero-btn {
    gap: 2rem;
  }

  .hero-single .hero-content .theme-btn {
    padding: 10px 14px;
  }

}


/* =====================
play btn
===================== */

.play-btn {
  display: inline-block;
  padding: 0;
  height: 75px;
  width: 75px;
  line-height: 75px;
  font-size: 20px;
  text-align: center;
  background: #00AF92;
  color: #fff !important;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
}

@keyframes ripple-wave {
  0% {
    opacity: 0.8;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

/* ===================
about css
====================== */

.about-area {
  position: relative;
  display: block;
}

.about-left {
  position: relative;
  display: block;
  margin-right: 30px;
}

.about-img {
  position: relative;
}

.about-img img {
  border-radius: 5px;
}

.about-img::before {
  content: "";
  position: absolute;
  left: 30px;
  top: 30px;
  width: 100%;
  height: 100%;
  background: #DBDCF4;
  border-radius: 5px;
  z-index: -1;
}

.about-right {
  position: relative;
  display: block;
  padding-left: 30px;
}

.about-list-wrapper {
  position: relative;
  display: block;
  margin-top: 32px;
  margin-bottom: 32px;
}

.about-list {
  position: relative;
  display: block;
}

.about-list li {
  position: relative;
  display: flex;
}

.about-list li + li {
  margin-top: 30px;
}

.about-list li .icon {
  width: 45px;
  height: 45px;
  position: relative;
  display: flex;
  align-items: center;
}

.about-list li .icon i {
  font-size: 35px;
  color: #00AF92;
}

.about-list li .text {
  margin-left: 16px;
}

.about-list li .text p {
  margin-top: 10px;
}

@media all and (max-width: 991px) {
  .about-right {
    margin-top: 80px;
  }

  .about-img img {
    width: 100%;
  }

}

@media all and (max-width: 767px) {
  .about-title {
    font-size: 30px;
  }

  .about-left {
    margin-right: 0;
  }

  .about-left-content {
    bottom: -70px;
  }

  .about-img {
    width: 90%;
  }
}

</style>