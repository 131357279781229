<template>
  <Layout>
    <Navigation>
      <div class="container mt-5 mb-5">
        <div class="row">
          <div class="col-md-12">
            <h2 class="site-title text-center mb-5">Explore Our Courses</h2>
            <div class="tab-content">
              <!-- Grid Tab -->
              <div id="grid" class="tab-pane fade in active show" role="tabpanel">
                <div class="row">
                  <div v-for="(item,index) in courses" :key="index" class="col-lg-4 col-md-6">
                    <div class="feature-course-item-3 fci-3">
                      <div class="fcf-thumb" @click="toDetails(item.id)">
                        <img :src="staticImage + item.thumbnail" alt="">
                      </div>
                      <div class="fci-details">
                        <h4 class="pointer" @click="toDetails(item.id)">
                          {{ item.name }}
                        </h4>
                        <div class="author">
                          <img :src="authorImg" alt="">
                          <a href="#">ONE ORG 出品</a>
                        </div>
                        <div class="price-rate">
                          <div class="course-price" v-if="item.type === 1001">
                            免费
                          </div>
                          <div class="course-price course-price-black" v-if="item.type === 1003">
                            邀请制
                          </div>
                          <div class="course-price course-price-red" v-if="item.type === 1002">
                            ￥{{item.price}}.00
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Grid Tab -->

              <!-- List Tab -->
            </div>
          </div>
        </div>
      </div>
    </Navigation>
  </Layout>
</template>

<script>
import Layout from '@layouts/main'
import Navigation from '@layouts/navigation'

import "@assets/css/course.css"

export default {
  name: 'index',
  components: {Layout, Navigation},
  data() {
    return {
      courseImg: require('../../../../assets/images/home3/course/1.jpg'),
      authorImg: require('../../../../assets/images/home3/course/a1.png'),
      courses: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const vm = this
      this.$axios.get('/Course/GetCourseList?pageIndex=1').then((res) => {
        vm.courses = res.data.data.data
      })
    },
    toDetails(id) {

      this.$router.push({path: '/courses/details', query: {id}})

      // 允许未购买查看内容

      // this.$axios.get('/Course/CheckValid', {params: {id}}).then((res) => {
      //   if (res.data.code === 1001) {
      //     this.$router.push({path: '/courses/details', query: {id}})
      //   }
      //   if (res.data.code !== 1001) {
      //     this.$message({
      //       message: res.data.msg,
      //       type: 'warning'
      //     })
      //   }
      // })


    }
  }
}
</script>